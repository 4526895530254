.home {
    display: flex;
    justify-content: space-between;
    min-height: 450px;
}

.vector-class-1 {
    width: 45%;
}

.abstract-vector {
    height: auto;
    width: 100%;
    justify-content: end;
}


.small-screen-flex{
    display: flex;
}


#light {
    .resume-button {
        display: flex;
        background: linear-gradient(134deg, $lightButtonGradientColor 0%, $lightTextColor 99%);
        color: rgb(248, 248, 248);
        border: none;
        cursor: pointer;
        transition: all 0.2s ease 0s;
        width: fit-content;
        height: fit-content;
        font-size: 0.8rem;
        margin: 5px;
        padding: 12px;
        border-radius: 5px;
    }

    .social-links {
        display: flex;
        margin: 5px;
    }

    .social-link-img {
        padding-right: 10px;
        margin-top: 15px;
        cursor: pointer;

        :hover {
            transform: scale(1.5);
        }
    }

    .content {
        position: absolute;
        color: $blackColor;
        display: flex;
        align-items: center;
        left: 40px;
        top: 200px;
        width: 90%;

        @media screen and (max-width: 400px) {
            top: 25px;
            left:25px
        }

        @media screen and (min-width: $medium ) and (max-width: $small) {
            top: 100px;
            left:20px
        }

        .class-editor-call {
            z-index: 1;
        }

        .text-content {
            width: 50%;

            p {
                font-size: 20px;
                margin: 2px;
            }

            @media screen and (max-width: 400px) {
                top: 25px;
            }
        }

        h1 {
            font-size: 4.2rem;
            line-height: 110%;
            margin: 0px;
            font-weight: 900;

            @media screen and (max-width: 400px) {
                font-size: 3.2rem;
                line-height: 110%;
                margin: 0px;
                font-weight: 700;
            }
        }
    }
}

#dark {
    .resume-button {
        display: flex;
        background: linear-gradient(134deg, $lightButtonGradientColor 0%, $darkHeadingColor 99%);
        color: $blackColor;
        border: none;
        cursor: pointer;
        transition: all 0.2s ease 0s;
        width: fit-content;
        height: fit-content;
        font-size: 0.8rem;
        margin: 5px;
        padding: 12px;
        border-radius: 5px;
    }

    .social-links {
        display: flex;
        margin: 5px;
    }

    .social-link-img {
        padding-right: 10px;
        margin-top: 15px;
        cursor: pointer;

        :hover {
            transform: scale(1.5);
        }
    }

    .content {
        position: absolute;
        color: $darkHeadingColor;
        display: flex;
        align-items: center;
        left: 40px;
        top: 200px;
        width: 90%;

        @media screen and (max-width: 400px) {
            top: 25px;
            left:25px
        }

        @media screen and (min-width: $medium ) and (max-width: $small) {
            top: 100px;
            left:20px
        }

        .class-editor-call {
            z-index: 1;
        }

        .text-content {
            width: 50%;

            p {
                font-size: 20px;
                margin: 2px;
            }

            @media screen and (max-width: 400px) {
                top: 25px;
            }
        }

        h1 {
            font-size: 4.2rem;
            line-height: 110%;
            margin: 0px;
            font-weight: 900;

            @media screen and (max-width: 400px) {
                font-size: 3.2rem;
                line-height: 110%;
                margin: 0px;
                font-weight: 700;
            }
        }
    }
}