#light {

    .label{
        font-size: 14px;
        color: $lightHeadingColor;
        margin: 14px;
    }

    .shake-hand {
        width: 100px;
        margin-top: 20px;
        height: 100px;
        text-align: center;
    }

    .query {
        font-size: 16px;
    }

    .thankyou-note {
        font-size: 20px;
        margin: 5px;
    }

    .thankyou-card {
        width: 20vw;
        height: fit-content;
        top: 65px;
        text-align: center;
        box-shadow: 15px 10px 10px rgb(0 0 0 / 10%);
        border-radius: 10px 0px 0px 10px;
        background-color: $vectorColor;
        color: $lightBackgroundColor;
    }

    .form-show {
        display: initial;
        color: #B61919;
        padding: 12px;
    }

    .form-hide {
        display: none;
        color: #B61919;
    }

    .getintouch-card {
        margin-right: auto;
        width: 50vw;
        padding: 13px;
        background: $lightBackgroundColor;
        box-shadow: 15px 10px 10px rgb(0 0 0 / 10%);
        border-radius: 10px;

        ::placeholder {
            color: $lightHeadingColor;
            opacity: 0.6;
            font-size: 14px;
        }

    }

    .getintouch-input {
        width: 100%;
        padding: 10px;
        margin: 10px;
        border: 0px solid $lightHeadingColor;
        background-color: transparent;
        color: $lightHeadingColor;
        font-weight: bold;
        border-radius: 10px;
        background-color: #eaeaea;
    }

    .getintouch-input-textarea {
        width: 100%;
        height: 150px;
        padding: 1em;
        margin: 10px;
        border: 0px;
        background-color: transparent;
        color: $lightHeadingColor;
        font-weight: bold;
        border-radius: 10px;
        background-color: #eaeaea;
        resize: none;
        :focus-visible {
            border: 1px solid $darkHeadingColor;
        }
    }

    .getintouch-card form a {
        position: relative;
        display: inline-block;
        padding: 10px 20px;
        color: $lightHeadingColor;
        font-size: 16px;
        text-decoration: none;
        text-transform: uppercase;
        overflow: hidden;
        transition: .5s;
        letter-spacing: 4px
    }

    .getintouch-card a:hover {
        background: $lightHeadingColor;
        color: $whiteColor;
        border-radius: 5px;
        box-shadow: 0 0 5px $lightHeadingColor,
            0 0 25px $lightHeadingColor,
            0 0 50px $lightHeadingColor,
            0 0 100px $lightHeadingColor;
    }

    .getintouch-card a span {
        position: absolute;
        display: block;
    }

    .getintouch-card a span:nth-child(1) {
        top: 0;
        left: -100%;
        width: 100%;
        height: 2px;
        background: linear-gradient(90deg, transparent, $lightHeadingColor);
        animation: btn-anim1 1s linear infinite;
    }

    @keyframes btn-anim1 {
        0% {
            left: -100%;
        }

        50%,
        100% {
            left: 100%;
        }
    }

    .getintouch-card a span:nth-child(2) {
        top: -100%;
        right: 0;
        width: 2px;
        height: 100%;
        background: linear-gradient(180deg, transparent, $lightHeadingColor);
        animation: btn-anim2 1s linear infinite;
        animation-delay: .25s
    }

    @keyframes btn-anim2 {
        0% {
            top: -100%;
        }

        50%,
        100% {
            top: 100%;
        }
    }

    .getintouch-card a span:nth-child(3) {
        bottom: 0;
        right: -100%;
        width: 100%;
        height: 2px;
        background: linear-gradient(270deg, transparent, $lightHeadingColor);
        animation: btn-anim3 1s linear infinite;
        animation-delay: .5s
    }

    @keyframes btn-anim3 {
        0% {
            right: -100%;
        }

        50%,
        100% {
            right: 100%;
        }
    }

    .getintouch-card a span:nth-child(4) {
        bottom: -100%;
        left: 0;
        width: 2px;
        height: 100%;
        background: linear-gradient(360deg, transparent, $lightHeadingColor);
        animation: btn-anim4 1s linear infinite;
        animation-delay: .75s
    }

    @keyframes btn-anim4 {
        0% {
            bottom: -100%;
        }

        50%,
        100% {
            bottom: 100%;
        }
    }

    .btn-align {
        text-align: center;
    }

    @media screen and (max-width: 768px) {
        .getintouch-card {
            min-width: 100%;
            margin-left: auto;
            margin-right: auto;
            padding: 13px;
            background: $lightBackgroundColor;
            box-shadow: 0 15px 25px rgba(0, 0, 0, .6);
            border-radius: 10px;

        }
    }
}

#dark {

    .label{
        font-size: 14px;
        color: $darkHeadingColor;
        margin: 14px;
    }

    .shake-hand {
        width: 100px;
        margin-top: 20px;
        height: 100px;
        text-align: center;
    }

    .query {
        font-size: 16px;
    }

    .thankyou-note {
        font-size: 20px;
        margin: 5px;
    }

    .thankyou-card {
        width: 20vw;
        height: fit-content;
        top: 65px;
        text-align: center;
        box-shadow: 15px 10px 10px rgb(0 0 0 / 10%);
        border-radius: 10px 0px 0px 10px;
        background-color: $vectorColor;
        color: $lightBackgroundColor;
    }

    .form-show {
        display: initial;
        color: #B61919;
        padding: 12px;
    }

    .form-hide {
        display: none;
        color: #B61919;
    }

    .getintouch-card {
        margin-right: auto;
        width: 50vw;
        padding: 13px;
        background: $darkBackgroundColor;
        box-shadow: 15px 10px 10px rgb(0 0 0 / 10%);
        border-radius: 10px;

        ::placeholder {
            color: $darkTextColor;
            opacity: 0.6;
            font-size: 14px;
        }

    }

    .getintouch-input {
        width: 100%;
        padding: 10px;
        margin: 10px;
        border: 0px solid $darkHeadingColor;
        background-color: transparent;
        color: $darkHeadingColor;
        font-weight: bold;
        border-radius: 10px;
        background-color: #232323;
    }

    .getintouch-input-textarea {
        width: 100%;
        height: 150px;
        padding: 1em;
        margin: 10px;
        border: 0px;
        background-color: #232323;
        color: $darkTextColor;
        font-weight: bold;
        border-radius: 10px;
        resize: none;

        :focus-visible {
            border: 1px solid $darkHeadingColor !important;
        }
    }

    .getintouch-card form a {
        position: relative;
        display: inline-block;
        padding: 10px 20px;
        color: $darkHeadingColor;
        font-size: 16px;
        text-decoration: none;
        text-transform: uppercase;
        overflow: hidden;
        transition: .5s;
        letter-spacing: 4px
    }

    .getintouch-card a:hover {
        background: $darkHeadingColor;
        color: #000;
        border-radius: 5px;
        box-shadow: 0 0 5px $darkHeadingColor,
            0 0 25px $darkHeadingColor,
            0 0 50px $darkHeadingColor,
            0 0 100px $darkHeadingColor;
    }

    .getintouch-card a span {
        position: absolute;
        display: block;
    }

    .getintouch-card a span:nth-child(1) {
        top: 0;
        left: -100%;
        width: 100%;
        height: 2px;
        background: linear-gradient(90deg, transparent, $darkHeadingColor);
        animation: btn-anim1 1s linear infinite;
    }

    @keyframes btn-anim1 {
        0% {
            left: -100%;
        }

        50%,
        100% {
            left: 100%;
        }
    }

    .getintouch-card a span:nth-child(2) {
        top: -100%;
        right: 0;
        width: 2px;
        height: 100%;
        background: linear-gradient(180deg, transparent, $darkHeadingColor);
        animation: btn-anim2 1s linear infinite;
        animation-delay: .25s
    }

    @keyframes btn-anim2 {
        0% {
            top: -100%;
        }

        50%,
        100% {
            top: 100%;
        }
    }

    .getintouch-card a span:nth-child(3) {
        bottom: 0;
        right: -100%;
        width: 100%;
        height: 2px;
        background: linear-gradient(270deg, transparent, $darkHeadingColor);
        animation: btn-anim3 1s linear infinite;
        animation-delay: .5s
    }

    @keyframes btn-anim3 {
        0% {
            right: -100%;
        }

        50%,
        100% {
            right: 100%;
        }
    }

    .getintouch-card a span:nth-child(4) {
        bottom: -100%;
        left: 0;
        width: 2px;
        height: 100%;
        background: linear-gradient(360deg, transparent, $darkHeadingColor);
        animation: btn-anim4 1s linear infinite;
        animation-delay: .75s
    }

    @keyframes btn-anim4 {
        0% {
            bottom: -100%;
        }

        50%,
        100% {
            bottom: 100%;
        }
    }

    .btn-align {
        text-align: center;
    }

    @media screen and (max-width: 768px) {
        .getintouch-card {
            min-width: 100%;
            margin-left: auto;
            margin-right: auto;
            padding: 13px;
            background: $darkBackgroundColor;
            box-shadow: 0 15px 25px rgba(0, 0, 0, .6);
            border-radius: 10px;

        }
    }
}