  .bar {
    margin-bottom: 10px;
  }

  .bar-heading {
    margin-right: 10px;
  }

  .chart-wrapper {
    margin: 0 auto;
    max-width: 60rem;
  }

  .chart-horizontal {
    margin: 19px;
    margin-bottom: 0px;
    padding: 0;
    list-style: none;
    overflow: hidden;
    box-shadow: 0 20px 25px -15px rgba(0, 0, 0, 0.8);
    height: 40px;
  }

  .chart-horizontal::after {
    clear: both;
    content: "";
    display: block;
  }

  .chart-horizontal .chart-bar-label {
    color: #FFFFFF;
    line-height: 1.625rem;
    margin-left: 0.3125rem;
    line-break: anywhere;
    word-break: break-all;
    text-transform: uppercase;
  }

  .chart-horizontal .chart-bar {
    -webkit-animation: 1s animation-lightspeed-in ease forwards;
    animation: 1s animation-lightspeed-in ease forwards;
    background: $vectorColor;
    height: fit-content;
    margin: 0px;
    padding: 11px;
    overflow: hidden;
  }

  .chart-horizontal .chart-bar:nth-of-type(10) {
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
  }

  .chart-horizontal .chart-bar:nth-of-type(9) {
    -webkit-animation-delay: 1.8s;
    animation-delay: 1.8s;
  }

  .chart-horizontal .chart-bar:nth-of-type(8) {
    -webkit-animation-delay: 1.6s;
    animation-delay: 1.6s;
  }

  .chart-horizontal .chart-bar:nth-of-type(7) {
    -webkit-animation-delay: 1.4s;
    animation-delay: 1.4s;
  }

  .chart-horizontal .chart-bar:nth-of-type(6) {
    -webkit-animation-delay: 1.2s;
    animation-delay: 1.2s;
  }

  .chart-horizontal .chart-bar:nth-of-type(5) {
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
  }

  .chart-horizontal .chart-bar:nth-of-type(4) {
    -webkit-animation-delay: 0.8s;
    animation-delay: 0.8s;
  }

  .chart-horizontal .chart-bar:nth-of-type(3) {
    -webkit-animation-delay: 0.6s;
    animation-delay: 0.6s;
  }

  .chart-horizontal .chart-bar:nth-of-type(2) {
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
  }

  .chart-horizontal .chart-bar:nth-of-type(1) {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
  }

  .chart-horizontal .chart-bar[data-skill="10"] {
    width: 10%;
  }

  .chart-horizontal .chart-bar[data-skill="20"] {
    width: 20%;
  }

  .chart-horizontal .chart-bar[data-skill="30"] {
    width: 30%;
  }

  .chart-horizontal .chart-bar[data-skill="40"] {
    width: 40%;
  }

  .chart-horizontal .chart-bar[data-skill="50"] {
    width: 50%;
  }

  .chart-horizontal .chart-bar[data-skill="60"] {
    width: 60%;
  }

  .chart-horizontal .chart-bar[data-skill="70"] {
    width: 70%;
  }

  .chart-horizontal .chart-bar[data-skill="80"] {
    width: 80%;
  }

  .chart-horizontal .chart-bar[data-skill="90"] {
    width: 90%;
  }

  .chart-horizontal .chart-bar[data-skill="100"] {
    width: 100%;
  }

  .percentage {
    float: right;
    font-weight: 600;
    color: #f5f5f5;
  }