// light theme colors
$lightTextColor: #B25068;
$lightBackgroundColor: #FEFEFE;
$lightHeadingColor: #16213E;
$lightHoverColor: #E49830;
$lightButtonGradientColor:#CA4E79;


// dark theme colors
$darkBackgroundColor: #161616;
$darkHeadingColor: #FFBC42;
$darkTextColor: #FCF6F5;
$darkHoverColor: #dddddd;


// general colors
$vectorColor: #B25068;
$blackColor: #24232E;
$whiteColor: #FAFAFA;
$editorVarColor : #ef6155;
$editorUserVarColor: #f99b15;
$editorLineNumberColor: #776e71;
$editorBackgroundColor: #2F1E2E;
$editorPropertyColor: #06b6ef;
$editorUserString: #fec418;
$editorClassProperty: #48b685;
$editorDarkTheme:#011627;

// constants
$small: 300px;
$medium: 900px;