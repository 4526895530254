.profile-image {
    width: 100%;
    height: auto;
}

.content-container {
    position: relative;
}

.my-img {
    text-align: center;
    position: relative;
    height: 200px;
    overflow: hidden;
    border-radius: 50%;
    width: 200px;
    margin-right: 50px;
    margin-bottom: 50px;
    float: right;
}


.my-information{
    color: $whiteColor;
    word-spacing: 3px;
    line-height: 30px;
}

.circular--portrait {
    position: relative;
    width: 110px;
    height: 110px;
    overflow: hidden;
    border-radius: 50%;
    border: 5px solid $lightHeadingColor;
}

.circular--portrait img {
    width: 100%;
    height: auto;
    background-color:  $lightHeadingColor;
}

.card-img{
    top: -7px !important;
    left: -10px !important;
}

#light {
    .my-img{
        border: 5px solid $lightBackgroundColor;
    }

    .aboutMe {
        min-height: 450px;
        background-color: $lightBackgroundColor;
    }

    .div-with-background {
        background: linear-gradient(134deg, $lightTextColor 0%, $vectorColor);
        position: absolute;
        min-width: 100%;
        min-height: 500px;
        margin-top: 40px;
        transform: skewY(-3deg);
        z-index: 0;
        left: 0;
        pointer-events: none;
        @media screen and (max-width: 400px) {
            min-height: 1200px;
        }

    }

    .my-information{
        a{
            color: $lightHeadingColor;
        }
    }

    .card-text{
        line-height: 150%;
        font-size: 14px;
        color: $lightHeadingColor;
    }

    .my-data-card-background{
        background-color: $lightBackgroundColor;
    }
}

#dark {

    .my-img{
        border: 5px solid $darkBackgroundColor;
    }

    .aboutMe {
        min-height: 450px;
        background-color: $darkBackgroundColor;
    }

    .div-with-background {
        background: rgb(35, 35, 35);
        position: absolute;
        min-width: 100%;
        min-height: 500px;
        margin-top: 40px;
        transform: skewY(-3deg);
        z-index: 0;
        left: 0;
        pointer-events: none;
        @media screen and (max-width: 400px) {
            min-height: 1200px;
        }

    }

    
    .my-information{
        a{
            color: $darkHeadingColor;
        }
    }

    .card-text{
        line-height: 150%;
        font-size: 14px;
        color: $darkHeadingColor;
    }

    .my-data-card{
        border-radius: 10px;
        background-color: $darkBackgroundColor;
    }
}